<template></template>

<script>
// Bar chart for "Active Users" card.
import ChartLine from '../Charts/ChartLine';

export default {
    props: {
        dataChart: { type: Object },
    },
    components: {
        ChartLine,
    },
    data() {
        return {
            lineData: this.dataChart,
        };
    },
    watch: {
        '$props.dataChart': function () {
            this.lineData = this.dataChart;
        },
    },
};
</script>
