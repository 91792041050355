<template>
    <div class="dashboard">
        <!-- Counter Widgets -->
        <a-row :gutter="24">
            <a-col :span="24" :lg="12" :xl="6" class="mb-24" v-for="(stat, index) in stats" :key="index">
                <!-- Widget 1 Card -->
                <WidgetCounter
                    :title="stat.title"
                    :value="stat.value"
                    :prefix="stat.prefix"
                    :suffix="stat.suffix"
                    :icon="stat.icon"
                    :status="stat.status"
                ></WidgetCounter>
                <!-- / Widget 1 Card -->
            </a-col>
        </a-row>
        <!-- / Counter Widgets -->

        <!-- Charts -->
        <a-row :gutter="24" type="flex" align="stretch">
            <a-col :span="24" :lg="24" class="mb-24">
                <a-card :bordered="false" class="dashboard-bar-line header-solid">
                    <template #title>
                        <h6>{{ $t('cms.visit_overview') }}</h6>
                    </template>
                    <template #extra>
                        <a-badge color="primary" class="badge-dot-primary" :text="$t('cms.visits')" />
                        <div class="chart__date">
                            <a-range-picker
                                @change="onChange"
                                :disabledDate="disabledDate"
                                :default-value="[initDateTime.start_time, initDateTime.end_time]"
                                :placeholder="[$t('cms.start_time'), $t('cms.end_time')]"
                            />
                        </div>
                    </template>
                    <ChartLine :height="310" :data="dataChart" v-if="dataChart"></ChartLine>
                </a-card>
            </a-col>
        </a-row>
        <!-- / Charts -->
    </div>
</template>

<script>
import CardBarChart from '../../components/CMS/Cards/CardBarChart';
import CardLineChart from '../../components/CMS/Cards/CardLineChart';
import WidgetCounter from '../../components/CMS/Widgets/WidgetCounter';
import CardProjectTable from '../../components/CMS/Cards/CardProjectTable';
import CardOrderHistory from '../../components/CMS/Cards/CardOrderHistory';
import CardInfo from '../../components/CMS/Cards/CardInfo';
import CardInfo2 from '../../components/CMS/Cards/CardInfo2';
import ChartLine from '../../components/CMS/Charts/ChartLine';
import { mixinLoadings, mixinToasts } from '../../mixins';
import { mapActions, mapState } from 'vuex';
import moment from 'moment';

// "Projects" table list of columns and their properties.
const tableColumns = [
    {
        title: 'COMPANIES',
        dataIndex: 'company',
        scopedSlots: { customRender: 'company' },
        width: 300,
    },
    {
        title: 'MEMBERS',
        dataIndex: 'members',
        scopedSlots: { customRender: 'members' },
    },
    {
        title: 'BUDGET',
        dataIndex: 'budget',
        class: 'font-bold text-muted text-sm',
    },
    {
        title: 'COMPLETION',
        scopedSlots: { customRender: 'completion' },
        dataIndex: 'completion',
    },
];

// "Projects" table list of rows and their properties.
const tableData = [
    {
        key: '1',
        company: {
            name: 'Soft UI Shopify Version',
            logo: 'images/logos/logo-shopify.svg',
        },
        members: ['images/face-1.jpg', 'images/face-4.jpg', 'images/face-2.jpg', 'images/face-3.jpg'],
        budget: '$14,000',
        completion: 60,
    },
    {
        key: '2',
        company: {
            name: 'Progress Track',
            logo: 'images/logos/logo-atlassian.svg',
        },
        members: ['images/face-4.jpg', 'images/face-3.jpg'],
        budget: '$3,000',
        completion: 10,
    },
    {
        key: '3',
        company: {
            name: 'Fix Platform Errors',
            logo: 'images/logos/logo-slack.svg',
        },
        members: ['images/face-1.jpg', 'images/face-2.jpg', 'images/face-3.jpg'],
        budget: 'Not Set',
        completion: {
            label: '100',
            status: 'success',
            value: 100,
        },
    },
    {
        key: '4',
        company: {
            name: 'Launch new Mobile App',
            logo: 'images/logos/logo-spotify.svg',
        },
        members: ['images/face-1.jpg', 'images/face-2.jpg'],
        budget: '$20,600',
        completion: {
            label: '100',
            status: 'success',
            value: 100,
        },
    },
    {
        key: '5',
        company: {
            name: 'Add the New Landing Page',
            logo: 'images/logos/logo-jira.svg',
        },
        members: ['images/face-1.jpg', 'images/face-4.jpg', 'images/face-2.jpg', 'images/face-3.jpg'],
        budget: '$4,000',
        completion: 80,
    },
    {
        key: '6',
        company: {
            name: 'Redesign Online Store',
            logo: 'images/logos/logo-invision.svg',
        },
        members: ['images/face-1.jpg', 'images/face-4.jpg', 'images/face-3.jpg'],
        budget: '$2,000',
        completion: {
            label: 'Cancelled',
            status: 'exception',
            value: 100,
        },
    },
];

export default {
    mixins: [mixinLoadings, mixinToasts],
    components: {
        CardBarChart,
        CardLineChart,
        WidgetCounter,
        CardProjectTable,
        CardOrderHistory,
        CardInfo,
        CardInfo2,
        ChartLine,
    },
    data() {
        return {
            // Associating table data with its corresponding property.
            tableData,

            // Associating table columns with its corresponding property.
            tableColumns,

            // Counter Widgets Stats
            stats: [
                {
                    title: this.$t('cms.today_new_users'),
                    value: 0,
                    prefix: '+',
                    status: 'success',
                    suffix: '+0%',
                    icon: `<v-icon class="fa-solid fa-user-plus" style="color: white" />`,
                },
                {
                    title: this.$t('cms.today_visit'),
                    value: 0,
                    prefix: '+',
                    status: 'success',
                    suffix: '0%',
                    icon: `
						<svg width="22" height="22" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path fill-rule="evenodd" clip-rule="evenodd" d="M3.17157 5.17157C4.73367 3.60948 7.26633 3.60948 8.82843 5.17157L10 6.34315L11.1716 5.17157C12.7337 3.60948 15.2663 3.60948 16.8284 5.17157C18.3905 6.73367 18.3905 9.26633 16.8284 10.8284L10 17.6569L3.17157 10.8284C1.60948 9.26633 1.60948 6.73367 3.17157 5.17157Z" fill="#111827"/>
						</svg>`,
                },
                {
                    title: this.$t('cms.total_user'),
                    value: 0,
                    prefix: '',
                    suffix: '',
                    icon: `<svg width="22" height="22" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path d="M9 6C9 7.65685 7.65685 9 6 9C4.34315 9 3 7.65685 3 6C3 4.34315 4.34315 3 6 3C7.65685 3 9 4.34315 9 6Z" fill="#111827"/>
							<path d="M17 6C17 7.65685 15.6569 9 14 9C12.3431 9 11 7.65685 11 6C11 4.34315 12.3431 3 14 3C15.6569 3 17 4.34315 17 6Z" fill="#111827"/>
							<path d="M12.9291 17C12.9758 16.6734 13 16.3395 13 16C13 14.3648 12.4393 12.8606 11.4998 11.6691C12.2352 11.2435 13.0892 11 14 11C16.7614 11 19 13.2386 19 16V17H12.9291Z" fill="#111827"/>
							<path d="M6 11C8.76142 11 11 13.2386 11 16V17H1V16C1 13.2386 3.23858 11 6 11Z" fill="#111827"/>
						</svg>`,
                },
                {
                    title: this.$t('cms.total_visit'),
                    value: 0,
                    prefix: '',
                    suffix: '',
                    icon: `<v-icon class="fa-solid fa-globe"  style="color: white" />`,
                },
            ],
            dataChart: null,
            initDateTime: {
                start_time: moment().subtract(7, 'days').format('YYYY/MM/DD'),
                end_time: moment().format('YYYY/MM/DD'),
            },
        };
    },
    async created() {
        await this.getChart({
            start_time: moment().subtract(7, 'days').startOf('day').valueOf(),
            end_time: moment().valueOf(),
        });
    },
    computed: {
        ...mapState('dashboard', ['userObj', 'loginHistoryObj', 'isLoadingUser']),
    },
    methods: {
        ...mapActions('dashboard', ['getDashboard']),

        disabledDate(current) {
            return current && moment().endOf('day').valueOf() < moment(current).valueOf();
        },

        onChange(date) {
            this.getChart({
                start_time: moment(date[0]).startOf('days').valueOf(),
                end_time: moment(date[1]).endOf('days').valueOf(),
            });
        },

        async getChart(params) {
            try {
                this.showLoading();
                const data = await this.getDashboard({
                    ...params,
                    data: 'history',
                });
                this.stats[0].value = data.userObj.new_user_today;
                this.stats[0].suffix = `${data.userObj.percentage_user || 0}%`;
                if (data.userObj.percentage_user < 0) {
                    this.stats[0].status = 'danger';
                }

                this.stats[1].value = data.loginHistoryObj.total_company_history_today;
                this.stats[1].suffix = `${data.loginHistoryObj.percentage_login_history || 0}%`;
                if (data.loginHistoryObj.percentage_login_history < 0) {
                    this.stats[1].status = 'danger';
                }

                this.stats[2].value = data.userObj.total_user;
                this.stats[3].value = data.loginHistoryObj.total_company_history;

                const { loginHistoryGrpCount } = data.loginHistoryObj;
                this.dataChart = {
                    labels: loginHistoryGrpCount.map((item) => item?.date?.split('/').reverse().join('/')),
                    datasets: [
                        {
                            label: this.$t('cms.visits'),
                            data: loginHistoryGrpCount.map((item) => Number(item?.count)),
                            fill: false,
                            borderColor: '#1890FF',
                            tension: 0.1,
                        },
                    ],
                };
            } catch (error) {
                this.showError(error);
            } finally {
                this.hideLoading();
            }
        },
    },
};
</script>

<style lang="scss">
.dashboard {
    & .ant-card-head-wrapper {
        align-items: flex-start;
    }
    & .dashboard-bar-line .ant-card-extra .ant-badge {
        text-align: right;
    }
}

.chart__date {
    margin-top: 20px;
    & .ant-calendar-picker,
    & .ant-card-head-title span {
        margin-left: 0;
    }
    & .ant-calendar-picker-input {
        display: flex;
        align-items: center;
    }
    & .ant-calendar-range-picker-separator {
        height: auto;
        line-height: 1;
    }
}
</style>
